import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { titleTemplate } from '@/helpers/meta/title-template';

@Component
export default class NewsBackground extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.news.title').toString()),
    };
  }

  mounted(): void {
    if (this.$route.hash) {
      setTimeout((): void => {
        location.href = this.$route.hash;
      }, 100);
    }
  }

  createSlug(title: string): string {
    return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  }

  articles = [
    {
      title: 'Zes tips als je wilt beginnen met beleggen.',
      content: `
        <h2>Zes tips als je wilt beginnen met beleggen</h2>
        <p>Het lijkt wel alsof iedereen om je heen belegt, en precies weet waar ze mee bezig zijn. Voor de ervaren beleggers kan dat zeker zo zijn. Maar de meeste jongeren of nieuwkomers op de beleggingsmarkt hebben toch wel hun vragen en soms zelfs twijfels. </p>
        <p>En misschien jij ook wel, wat slim is. Want er zijn namelijk nogal wat tips en tricks om goed voorbereid te beginnen met beleggen. De zes belangrijkste staan hier op een rij.</p>
        <h2>Tip 1: begin zo snel mogelijk met beleggen</h2>
        <p>Het doel van beleggen is geld verdienen. Hoe eerder je met beleggen begint, hoe meer geld je kunt verdienen. Het voordeel van beginnen met beleggen als je jong bent, is dat je nog jaren hebt om je kapitaal te laten groeien. </p>
        <p>Je hebt de tijd om mogelijke tegenvallers op de markt weer goed te maken. En je profiteert lange tijd van het rendement-op-rendement effect. Je kunt namelijk het rendement dat je behaalt weer investeren om weer nieuw rendement te behalen. </p>
        <p>Een ander voordeel is dat je door de jaren heen steeds meer leert over beleggen. Over een aantal jaren ben je een ervaren belegger, met nog meer mogelijkheden op de beleggingsmarkt.</p>
        <h2>Tip 2: beleg alleen met eigen geld dat je kunt missen</h2>
        <p>Met beleggen bestaat het risico dat je een deel van of al je geld verliest. Beleg daarom alleen het geld dat je ‘over’ hebt, geld dat je niet nodig hebt. Houd altijd een reserve voor bijvoorbeeld je studie, een nieuwe wasmachine of iets anders dat voor jou belangrijk of noodzakelijk is.</p>
        <p>Als je een lening of een schuld hebt, heb je eigenlijk geen geld over om te beleggen. Los deze dan ook eerst af voordat je gaat beleggen. Beleg alleen als je je financiën goed op orde hebt.</p>
        <h2>Tip 3: weet waar je het over hebt</h2>
        <p>Beleggen kan in veel vormen. Om de juiste keuze te maken hoe en waarin je wilt beleggen zul je wat huiswerk moeten doen. Je hoeft niet er niet meteen een hele studie van te maken, maar zorg dat je in ieder geval bekend bent met de volgende punten:</p>
        <strong>Het risicoprofiel van het soort belegging</strong>
        <p>Je kunt je voorstellen dat beleggen in woningen een veel lager risicoprofiel heeft dan bijvoorbeeld beleggen in aandelen. Over het algemeen geldt: hoe hoger het risicoprofiel, hoe hoger het rendement. Deze twee kun je dus niet los van elkaar zien.</p>
        <strong>Het gemiddeld behaalde rendement</strong>
        <p>‘In het verleden behaalde rendementen bieden geen garantie voor de toekomst.’ Dat is absoluut waar! Maar daarom is het nog wel verstandig om deze te kennen. En dan vooral ook de laagst behaalde rendementen in een periode. Want ook dat kan weer gebeuren. Op het internet vind je diverse overzichten van rendementen, bijvoorbeeld van de Rabobank.</p>
        <strong>De kosten voor instappen en wijzigingen</strong>
        <p>De kosten van beleggen worden vaak vergeten of onderschat. Maar zij beïnvloeden wel je rendement en verschillen sterk. De directe kosten waaronder commissie, beheervergoeding en transactiekosten zijn vaak goed zichtbaar. Maar let ook op de indirecte kosten, zoals bijvoorbeeld een service fee. Deze worden soms ongemerkt verwerkt in de koersen.</p>
        <strong>Rendementsberekeningen</strong>
        <p>Maak voor zowel goede als voor slechte scenario’s rendementsberekeningen. Online vind je verschillende sites waar dit kan. Bijvoorbeeld op berekenhet.nl. Dit geeft je een idee van hoe snel of langzaam je kapitaal kan groeien. </p>
        <h2>Tip 4: spreid op meerdere manieren</h2>
        <p>Niet op één paard wedden klinkt wat suf, maar is ook absoluut waar! Door te beleggen in verschillende categorieën, spreid je je risico. Denk dan aan aandelen, obligaties, vastgoed, valuta en grondstoffen. Binnen ieder van deze categorieën is weer een verdere spreiding naar sectoren mogelijk. </p>
        <p>Een ander manier van spreiden is per regio. Je kunt kiezen voor beleggen in alleen Nederland, Europa of wereldwijd gaan beleggen. Ook dit is een manier van risicospreiding.</p>
        <h2>Tip 5: ontdek wat voor type belegger jij bent</h2>
        <p>Behalve een nieuwe belegger, ben je nog veel meer. Stel je zelf een paar belangrijke vragen. Wil je actief met je beleggingen bezig zijn, de markt volgen en veel transacties doen? Of vind je het prima als je eens in de zoveel tijd de resultaten ziet? Heb je interesse in bepaalde categorieën die je met plezier volgt? Is duurzaamheid een belangrijk uitgangspunt voor jou?</p>
        <p>Maar stel je zelf ook zeker de financiële vragen. Wat is je doel, welk eindkapitaal heb je in gedachten? Hoe ver liggen je doelstellingen, wil je op korte of op lange termijn resultaat? Hoeveel risico wil je lopen? Wil je je rendement weer beleggen of wil je dat opnemen? </p>
        <p>Je zult zien dat bij jouw profiel bepaalde beleggingen beter passen dan andere. En dat dit in de loop van de jaren weer kan veranderen, is logisch. In Business Insider lees je over drie strategieën gekoppeld aan je levensfase.</p>
        <h2>Tip 6: wees realistisch en maak je eigen keuze</h2>
        <p>Uiteindelijk kan alleen jij bepalen waarin je wilt beleggen en hoeveel je wilt beleggen. Maar welke keuzes je ook maakt, het heeft geen zin om je rijk te rekenen. Een realistisch doel voorkomt teleurstellingen en impulsieve of emotionele beslissingen. Ook is geld overhouden een goede motivatie om te beleggen volgens je plan.</p>
        <p>Met bovenstaande tips maak je een goed voorbereide start met goed overwogen keuzes. Wees realistisch, neem onderbouwde stappen, maar blijf groot denken!</p>
        <p><em>Bronnen: Rabobank / Berekenhet.nl / Business Insider</em></p>
      `,
      expanded: false,
      image: 'background11.jpg',
    },
    {
      title: 'Beleggen in vastgoed of in aandelen?',
      content: `
        <p>Om succesvol te beleggen is het belangrijk dat je een beleggingsvorm kiest die bij jou past. Bij jouw mogelijkheden, eisen en wensen. Een van de keuzes is beleggen in vastgoed of in aandelen. Waar kun je beter in beleggen? Wat zijn de voor- en nadelen van deze vormen van beleggen? Wij zetten dit hier voor je op een rij. Zodat jij goed geïnformeerd jouw beleggingsstrategie bepaalt!</p>
        <h2>Startkapitaal, instappen en ‘cashen’</h2>
        <p>Beleggen in vastgoed kan direct of indirect. Bij indirect beleggen neem je deel aan een vastgoedfonds. Zelf een pand, een appartement of ander onroerend goed bezitten is direct beleggen in vastgoed.</p>
        <p>Het grootste obstakel bij direct beleggen in vastgoed is vaak het grote bedrag dat je in één keer neer moet leggen. Je hebt eigen vermogen, ofwel kapitaal nodig. Er gaat flink wat tijd en geld in de keuze en aankoop van onroerend goed zitten. En als je het vastgoed hebt gekocht, zit je geld daar redelijk in vast. Je verkoopt het niet zo snel om het geld even voor iets anders te gebruiken. Kortom: vastgoed is minder liquide.</p>
        <p>Door indirect te beleggen in vastgoed, valt het probleem van voldoende eigen geld moeten hebben weg. Met een veel lagere inleg kun je vaak al deelnemen aan een vastgoedfonds.</p>
        <p>Om te beleggen in aandelen heb je nog minder eigen geld nodig. Zo kun je al starten met beleggen met minder dan 10 euro. Aandelen kun je snel ‘cashen’. Zij zijn, net als de participaties in een vastgoedfonds, liquide.</p>
        <h2>De waarde: stabiele en minder stabiele beleggingen</h2>
        <p>Een groot voordeel van beleggen in onroerend goed is dat de waarde van onroerend goed redelijk stabiel is. Iets stabieler dan de waarde van aandelen. Bij de waarde van aandelen speelt sentiment een rol. Als er vertrouwen in de markt is, stijgen de aandelenkoersen en andersom. Bij vastgoed speelt dit veel minder een rol. Vastgoed is ook minder gevoelig voor inflatie (ING).</p>
        <h2>Waardeontwikkeling op langere termijn</h2>
        <p>Een ander voordeel van beleggen in vastgoed is dat vastgoed op de langere termijn vrij zeker meer waard wordt. De waardestijging levert rendement op langere termijn op. Toch kan de waarde van vastgoed ook dalen, zoals bijvoorbeeld tijdens de financiële crisis in 2008. De daling was ongeveer 20% (ING), maar dit was niets vergeleken met de waardedaling van aandelen tijdens de beurscrash.</p>
        <p>Huuropbrengst versus opbrengst uit aandelen</p>
        <p>Naast het rendement op langere termijn door de waardestijging, heb je bij vastgoed ook direct rendement van de huurinkomsten. Huur is over het algemeen stabiel en dus kun je rekenen op een redelijk constante inkomstenstroom. Dit geeft vaak meer financiële zekerheid dan opbrengsten uit aandelen.</p>
        <h2>Verschillende risico's en spreiding</h2>
        <p>Het grootste risico van beleggen in vastgoed is leegstand. Maar met een beetje kennis van de markt is dit goed controleerbaar. Het leegstandsrisico op bijvoorbeeld de huidige woningmarkt is heel laag.</p>
        <p>Bij aandelen liggen de risico’s anders. Een aandeel is vaak minder transparant. Het achterliggende bedrijf, het product, de mensen, en de sector waarin een bedrijf actief is, zijn niet altijd eenvoudig te doorgronden.</p>
        <p>Met verschillende soorten beleggingen spreid je je risico. Diversificatie is het makkelijkst te realiseren met aandelen. Je kunt deelnemen in beleggingsfondsen in verschillende landen en sectoren.</p>
        <p>Bij direct in onroerend goed beleggen is de spreiding heel beperkt. Bij de particuliere belegger houdt het vaak bij één of een paar objecten wel op. De diversificatie bij het indirect beleggen is groter. In de vastgoedfondsen zitten vaak meerdere vastgoedobjecten. En je kunt kiezen voor bijvoorbeeld een winkelfonds, een woningfonds, een kantorenfonds of een mixfonds.</p>
        <h2>Rendement is altijd een inschatting</h2>
        <p>Het rendement op vastgoed verschilt per type vastgoed en fonds. Het hangt af van veel factoren, bijvoorbeeld de financiering. De meeste cijfers over behaalde rendementen bewegen zich rond de 6%.</p>
        <p>Het rendement op aandelen loopt veel sterker uiteen dan bij vastgoed. Dit heeft alles met de waarde van de aandelen te maken. In de prognose van Robeco ligt het gemiddelde jaarlijkse rendement in euro tussen 2021 en 2025 voor aandelen tussen de 4,75% en 6,75% (Business Insider).</p>
        <h2>Beleg verstandig en met plezier!</h2>
        <p>Beleggen is populair en de verwachting is dat steeds meer mensen gaan beleggen. Dit komt onder andere door de lage rente op spaargeld en herstel van de beurskoersen (Kantar). En beleggen wordt steeds toegankelijker. Via online platformen en apps beheer je eenvoudig je portefeuille.</p>
        <p>De keuze waarin je gaat beleggen hangt af van veel factoren. Los van de voor- en nadelen van beleggingsvormen, kun je ook een persoonlijke voorkeur hebben. Voor veel mensen is vastgoed iets tastbaars, en vaak ook leuks. Anderen raken enthousiast over bijvoorbeeld innovatieve bedrijven. Wat je voorkeur ook heeft, weeg de voor- en nadelen goed af. En bekijk goed je mogelijkheden en wensen.</p>
      `,
      expanded: false,
      image: 'background10.jpg',
    },
    {
      title: '7 Podcasts die je helpen met een moneymindset',
      content: `
        <p>Op zoek naar nieuw luistermateriaal dat je meeneemt in de wereld van geld, ondernemen, financiën, sparen & beleggen? Zoek dan niet langer, want wij hebben er een aantal voor jou op rij gezet!</p>
        <h2>1. Hoeveel ben ik waard? - <a href="https://podcastluisteren.nl/pod/Hoeveel-ben-ik-waard" target="_blank">Link naar podcast</a></h2>
        <p>In ‘Hoeveel ben ik waard?’ probeert Rolien Magendans erachter te komen hoeveel ze nou eigenlijk waard is. En dan bedoelt ze niet als moeder, als partner, of als waarde voor de maatschappij. Nee, Rolien probeert erachter te komen hoeveel geld ze nou eigenlijk waard is. Na het vinden van haar passie: het maken van podcasts, vraagt ze zich een aantal dingen af. Hoe pak je zoiets aan? Hoeveel vraag je per uur en hoe bereken je je uurprijs dan? Kortom: hoe weet je wat je waard bent? In haar podcast gaat Rolien in gesprek met mensen waarvan ze denkt dat zij dit pad al bewandeld hebben. Ze is benieuwd naar hun eerste stappen naar het ondernemerschap en hoopt dat zij haar, en haar luisteraars, kunnen inspireren.</p>
        <h2>2. Jong Beleggen - <a href="https://jongbeleggendepodcast.nl/" target="_blank">Link naar podcast</a></h2>
        <p>Pim Verlaan en Milou Brand maken in hun podcast ‘Jong Beleggen’ luisteraars wegwijs in de beleggerswereld. Nadat Pim zijn onderneming verkocht en veel geld tot zijn beschikking kreeg, besloot hij daar wat mee te doen. Tijdens zijn sabbatical van acht maanden verdiepte hij zich in vermogensbeheer en kwam hij tot de conclusie dat beleggen het beste bij hem past. Hij ervaarde zelf dat informatie over beleggen in begrijpelijke taal nauwelijks te vinden is. Na met veel deskundigen erover te hebben gepraat, besloot hij wat met zijn kennis te doen. Samen met Milou Brand startte hij vorig jaar de podcast ‘Jong Beleggen’. Pim en Milou nemen je vanaf de eerste aflevering mee in hun zoektocht naar de kunst van het dividendbeleggen en delen hun strategie en ontdekkingen.</p>
        <h2>3. Goed met Geld - <a href="https://goedmetgeldpodcast.nl/" target="_blank">Link naar podcast</a></h2>
        <p>Ben jij een millennial die meer uit je geld wilt halen? Dan is ‘Goed met Geld’ dé podcast voor jou. In Goed met Geld praten Bas en Arjan over geldzaken en financiële onafhankelijkheid. Als twee echte millennials doen ze dat luchtig en met humor, en mikken ze dus ook bewust op hun generatiegenoten. Maar niet getreurd: ook als je geen millennial bent, is Goed met Geld leuk om te luisteren. Bas en Arjan nodigen namelijk iedereen uit om ook Goed met Geld te worden.</p>
        <h2>4. POEN - <a href="https://www.nporadio1.nl/podcasts/poen" target="_blank">Link naar podcast</a></h2>
        <p>In POEN van de NOS kun je luisteren naar verhalen over geld. Verslaggevers Nik Wouters en Charlotte Waaijers duiken in de grote verhalen uit de wereld van het geld, de pegels, de monnies. In deze podcast krijg je een goed beeld van hoe de economische wereld werkt, vaak verpakt in grote nieuwswaardige verhalen. Zo begrijp je na het luisteren van POEN beter hoe de economie werkt en wat je ermee moet</p>
        <h2>5. Beleggen met Rowan Nijboer - <a href="https://www.rowannijboer.nl/podcast" target="_blank">Link naar podcast</a></h2>
        <p>Met ‘Beleggen met Rowan Nijboer’ probeert Rowan Nijboer beleggen toegankelijk te maken voor iedereen. Hij is ervan overtuigd dat iedereen kan beleggen en dat tijd, geld en moeite daarin geen rol spelen. Nijboer gelooft namelijk dat er een geschikte manier van beleggen is voor iedereen. De podcast maakt hij samen met zijn vriendin Barbara, die hem kritische vragen stelt om hem scherp te houden en om zijn luisteraars een zo duidelijk mogelijk beeld te geven van beleggen.</p>
        <h2>6. De AEX Factor - <a href="https://www.bnr.nl/podcast/aex-factor" target="_blank">Link naar podcast</a></h2>
        <p>De opvolger van de BNR Beurswatch podcast is de AEX Factor. Deze podcast is een nieuw programma over beurzen, beleggen, en vermogen opbouwen. Van Bitcoin tot Tesla en van goud tot sojabonen. Het komt allemaal wekelijks aan bod. De AEX Factor duikt dieper in de wereld van geld: financieel onafhankelijk vóór je dertigste of gewoon een zorgeloze oude dag? En daarbij kijken ze verder dan alleen beleggen in aandelen.</p>
        <h2>7. Nieuw Geld van VICE - <a href="https://open.spotify.com/show/5bcL7fJQUIo9J32hZY0c4f" target="_blank">Link naar podcast</a></h2>
        <p>In de podcast ‘Nieuw Geld’ vraagt VICE de mensen die het nieuwe geld verdienen hoe ze dat precies doen en geven experts context over de wereld waarin dat geld verdiend wordt.  Nieuw Geld onderzoekt nieuwe manieren waarop jonge mensen hun inkomen verdienen die vijf jaar geleden misschien nog niet mogelijk waren. De podcast herdefinieert de traditionele definitie van ‘nieuw geld’ door te laten zien dat we te maken hebben met een generatie die op een nieuwe manier naar geld kijkt. Ze verdienen het op een andere manier en gaan er anders mee om.</p>
      `,
      expanded: false,
      image: 'background9.jpg',
    },
    {
      title: 'Een financiële zorgeloze toekomst? De ‘financefluencer’ krijgt het voor elkaar.',
      content: `
        <p>Op Instagram zie je ze steeds vaker voorbijkomen, de ‘financefluencers’. De tijd waarin Instagram enkel nog gebruikt wordt voor perfecte selfies en zonnige vakantiefoto’s is voorbij. Het platform biedt namelijk steeds meer inspiratie voor diegenen die meer inzicht willen krijgen in hun geldzaken. Dit mede mogelijk gemaakt door de financefluencers, influencers die dromen van financiële vrijheid en zodoende hun tips en trucs delen over hoe je het beste met je geld om kan gaan.</p>
        <h2>Budgettips</h2>
        <p>Of je nu wilt besparen, leren budgetteren, of je studieschuld wilt aflossen: de financefluencer legt uit hoe je het doet. Zo ook Emma Mouthaan (25) van blog <a href="https://skerestudent.com/" target="_blank">Skere Student</a>, die na haar studie een studieschuld van €65.000 had opgebouwd en zich geschrokken afvroeg waarom ze zich nooit eerder in haar geldzaken verdiept had.</p>
        <p>Tegenwoordig is ze een van de tientallen influencers in Nederland die zich richt op persoonlijke financiën. Zo deelt ze elke maand naast haar inkomsten en uitgaven, ook diverse beleggings- en (be)spaartips met anderen.</p>
        <h2>Zoeken naar andere manieren</h2>
        <p>Dat het aandeel influencers dat blogt over geldzaken de afgelopen jaren zo is gestegen, is niet gek. Jongeren zijn namelijk steeds bewuster bezig met geld. Waar je jaren geleden nog een paar procent rente over je spaargeld kreeg, schommelt de rente dit jaar rond het nulpunt.</p>
        <p>Met het oog op inflatie wordt spaargeld dus minder waard en verdiepen jongeren zich steeds vaker in andere mogelijkheden om hun geld te laten renderen. Financefluencers kunnen daarbij van waarde zijn, want ook zij verdiepen zich in manieren waarop hun geld meer waard kan worden.</p>
        <p>Zo blogt Nicole van Roekel (25) van <a href="https://www.thebudgetlife.nl/" target="_blank">The Budget Life</a> naast haar tips om meer met minder geld te kunnen doen, ook over hoe je kunt starten met beleggen. Ze besloot zich te verdiepen in de beleggerswereld en heeft intussen al ruim €7000 aan belegd vermogen.</p>
        <h2>Financiële vrijheid</h2>
        <p>Nog zo’n iemand die precies weet hoe je ervoor zorgt dat je geld voor je gaat werken is Marjan Heemskerk (34), bekend van haar blog en vlogkanaal <a href="https://thehappyfinancial.com/" target="_blank">The Happy Financial</a>. Volgens haar eenvoudig te volgen 5-stappenmodel kan "zelfs de grootste struisvogel de weg naar financiële vrijheid vinden". Want als je eenmaal weet wat je precies moet doen, zijn financiën niet alleen maar een hoofdpijndossier, maar volgens Heemskerk juist een manier om je doelen te bereiken.</p>
        <p>Als afgestudeerd accountant bevat Heemskerk over de financiële expertise en schrijft ze dagelijks artikelen over alles wat met persoonlijke financiën te maken heeft: van belastingen tot beleggen en van sparen tot verzekeren.</p>
        <h2>Open zijn over geld</h2>
        <p>Arjan Vliegenthart, directeur van voorlichtingsorganisatie <a href="https://www.nibud.nl/" target="_blank">Nibud</a>, is blij om te zien dat steeds meer influencers zich uitspreken over hun persoonlijke geldzaken en daarmee het taboe over geld (of het gebrek daaraan) doorbreken. Hij benadrukt wel dat er een verschil is in kwaliteit van tips.</p>
        <p>Volgens Vliegenthart geven sommige influencers zulke goede tips dat ze bijna budgetcoach hadden kunnen zijn. Andere influencers delen soms adviezen die bij Nibud als ongezond gedrag zou worden bestempeld, zoals beleggen met je studielening. Dat is riskant, want geleend moet op een bepaald moment worden terugbetaald, en beleggen is geen garantie voor het behouden van je geld.</p>
        <p>Het blijft dus belangrijk om je niet alleen maar te laten leiden door succesverhalen en altijd je gezonde verstand te gebruiken als je een financiële beslissing maakt.</p>
      `,
      expanded: false,
      image: 'background1.jpg',
    },
    {
      title: 'Op je 40e met pensioen? FIRE-aanhangers krijgen het voor elkaar.',
      content: `
        <p>Als je bij FIRE alleen nog aan vuur denkt, heb je de laatste ontwikkeling gemist. De FIRE-beweging is de afgelopen jaren namelijk over komen waaien vanuit de Verenigde Staten en staat voor Financially Independent & Retire Early. Het afgelopen jaar werd FIRE steeds populairder onder millennials in Nederland. Maar hoe doe je dat? Hoe word je dermate financieel onafhankelijk dat je zelf kan bepalen wanneer je stopt met werken?</p>
        <h2>Financiële onafhankelijkheid</h2>
        <p>Aanhangers van de FIRE-beweging doen dit door een groot deel van hun salaris te sparen, te beleggen en te investeren in vastgoed. <a href="https://toscagort.nl/" target="_blank">Tosca Gort</a>, arbeidspsycholoog, legt uit dat mensen in de FIRE-beweging geen loonslaaf willen zijn, maar juist vrijheid nastreven. Gort: "Vrijheid is al een poos één van de belangrijkste westerse waarden en komt ook hierin tot uiting."</p>
        <p>Om genoeg geld te kunnen beleggen en investeren, moeten de FIRE-aanhangers rekening houden met twee dingen: hun inkomen verhogen en hun uitgaven extreem laag houden. Het algemene idee is dat hoe hoger het inkomen en hoe lager de uitgaven, hoe sneller men financiële onafhankelijkheid bereikt.</p>
        <h2>Eigen baas</h2>
        <p>Dit betekent echter niet dat ‘financiële onafhankelijkheid’ per se gepaard gaat met de hele dag golfen of alleen maar op een tropisch eiland zitten. Het betekent dat je voor jezelf een punt bereikt waarop je geen fulltime baan hoeft te werken als je dat niet wilt. Zo kan je bepalen om parttime te gaan werken of helemaal te stoppen. Die keus blijft voor elke FIRE-aanhanger persoonlijk.</p>
        <h2>Vrijheid</h2>
        <p>Puck Landawé, oprichtster van het platform <a href="https://fireforwomen.com/" target="_blank">FIRE for women</a>, geeft aan dat ze werk wil doen waar ze blij van wordt en niet per se omdat het moet. Landawé: "Ik kan bijna met pensioen gaan als ik dat wil en zuinig leef. Maar dat is niet mijn doel, want ik vind mijn werk heel leuk. Ik wil anderen juist laten zien hoe bevrijdend FIRE is." Op haar platform bespreekt ze alle onderwerpen die met een
        money mindset te maken hebben: hoe je kan beleggen, sparen en meer verdienen.</p>
        <h2>Niet voor iedereen</h2>
        <p>Een belangrijke voorwaarde van de FIRE-beweging blijft wel dat je voldoende inkomen moet hebben. Veel FIRE-voorstanders zijn het ermee eens dat hoe veel je ook gaat bezuinigen op je levensstijl, er een groot inkomen voor nodig is om genoeg te kunnen sparen om voor je 40e verjaardag met pensioen te gaan. Daarbij komt ook dat met het oog op de toekomst, inflatie er voor zal zorgen dat je spaargeld minder waard wordt. Daarom is het bij FIRE van groot belang dat je je spaargeld voor je laat werken.</p>
        <h2>Beleggen in vastgoed</h2>
        <p>Volgens Landawé is beleggen een cruciaal onderdeel om FIRE te worden, omdat het dé manier is om van je geld meer geld te maken. Zo bezit ze een beleggingsobject in Amersfoort en belegt ze door middel van ETF’s (Exchange Traded Funds) in verschillende vastgoedfondsen. Beleggen in vastgoed is volgens Landawé niet alleen heel leuk, maar ook erg lucratief. In de toekomst hoopt ze dan ook minimaal nog twee vastgoedinvesteringen te doen.</p>
        <p>Landawé benadrukt wel dat het belangrijk is om een flinke spaarbuffer aan te leggen voordat je met beleggen begint. Beleggen biedt namelijk geen garantie op het behouden van je geld, en daarom kan je het best beleggen met geld wat je in het dagelijks leven niet nodig hebt. Ook raadt ze aan om te zorgen dat je je geld in je portefeuille goed spreidt en om je kosten van het beleggen zo laag mogelijk te houden.</p>
        <p>Al met al, hoef je volgens Landawé niet rijk te zijn om te starten met beleggen. Volgens haar kan je niet snel genoeg beginnen. Landawé: "Al beleg je maar met drie tientjes in de maand. Het gaat erom dat je het heft in eigen handen neemt en slimme dingen doet met je geld!"</p>
      `,
      expanded: false,
      image: 'background2.jpg',
    },
    {
      title: 'Waarom beleggen in vastgoedfondsen loont: 5 voordelen op rij',
      content: `
        <p>Er zijn twee manieren om in vastgoed te investeren: direct en indirect. Met een directe vastgoedinvestering wordt het zelf kopen van een vastgoedobject bedoeld, waar je na aankoop van een pand persoonlijk aansprakelijk voor bent. Indirect beleggen in vastgoed wil zeggen dat je deelneemt in een vastgoedfonds. Vaak koop je niet het hele vastgoedobject, maar certificaten van aandelen in een fonds (eigenlijk een grote pot), dat in vastgoed investeert. Maar waarom kiest men voor het beleggen in een vastgoedfonds? Welke voordelen heeft dat eigenlijk?</p>
        <h2>1.Inflatiebestendige belegging</h2>
        <p>Dit voordeel gaat op voor zowel direct als indirect beleggen. Vastgoed is een materiële waarde en beschermt je zodoende tegen inflatie. Anders dan bij vermogen op je spaarrekening gaat het bij vastgoed om materiële waarden. Het grote verschil tussen een geldwaarde en een materiële waarde is dan ook dat 1) achter een materiële waarde een reële waarde schuilgaat en dat 2) deze reële waarde bij een geldwaarde ontbreekt. Een vastgoedobject blijft dus altijd iets waard.</p>
        <p>Mocht een vastgoedobject verhuurd worden, dan bewegen de huuropbrengsten zich over het algemeen ook mee met de inflatie. Is er inflatie, dan zullen de huurprijzen en dus ook de huuropbrengsten stijgen. Wanneer de inflatie aantrekt, zullen de inkomsten uit vastgoed ook stijgen.</p>
        <h2>2.Klein investeringsbedrag</h2>
        <p>Een groot voordeel van een vastgoedfonds is dat je met weinig geld kunt beginnen. Waar je bij een directe aankoop van een vastgoedobject zeker 4 tot 6% eigen geld nodig hebt om het object te kunnen kopen, kun je bij een vastgoedfonds voor relatief weinig geld instappen. Zo kun je bij sommige fondsen al instappen voor een bedrag van € 5.000, maar ook al vanaf € 250,- zoals bij Pebbles. Natuurlijk kun je altijd besluiten om voor een hoger bedrag in te stappen en daardoor meer certificaten van aandelen van een vastgoedfonds aan te schaffen.</p>
        <h2>3.(Grote) risicospreiding</h2>
        <p>Een vastgoedfonds investeert in diverse en verscheidene soorten vastgoed. Zelfs als je slechts in één vastgoedfonds investeert, spreid je je financiële risico al aanzienlijk. Zodoende kun je ook met een klein budget een grote spreiding realiseren en je zekerheid op die manier vergroten. Dit is anders bij direct beleggen in vastgoed, waar je vaak al je geld in één of enkele vastgoedobjecten stopt.</p>
        <h2>4.Beheer word je uit handen genomen</h2>
        <p>Een vierde voordeel is dat je na het instappen in een vastgoedfonds, zelf geen van de vastgoedobjecten hoeft te beheren. Dat doet een beheerder voor je. Dit betekent dat je je nog nauwelijks met de investering bezig hoeft te houden. Een beheerder neemt o.a. het dagelijkse contact met de huurder op zich en checkt of de huur op tijd binnenkomt. Ook reparaties, termijnenbeheer en het zoeken van een eventuele nieuwe huurder kun je aan de beheerder over laten. Zo neemt de beheerder je werk uit handen en schept hij een zekere afstand tussen jou en de huurders van het vastgoedobject waar jij in deelneemt.</p>
        <h2>5.Voor vastgoed is altijd belangstelling</h2>
        <p>In 2020 waren er zo’n 331.000 woningen te weinig, en dat tekort zal de komende jaren verder oplopen tot 419.000 in 2025. Iedereen wenst immers een dak boven zijn hoofd, of dit nu een woning, appartement of een vakantiehuisje is. Iedereen wil ergens kunnen wonen. </p>
        <p>Als investeerder in een vastgoedfonds speel je precies in op deze behoefte. Je hoeft namelijk niet bang te zijn dat morgen geen vastgoed meer gebruikt zal worden.</p>
      `,
      expanded: false,
      image: 'background3.jpg',
    },
    {
      title: 'Indirect investeren in woningen heeft veel voordelen',
      content: `
        <p>Voor veel beleggers is indirect investeren dé oplossing. Bij indirect investeren neem je deel aan een vastgoedfonds. Voor een relatief laag instapbedrag koop je al participaties in een fonds.</p>
        <p>Je kunt participaties in verschillende fondsen kopen. Daarmee spreid je je investering. Je kunt de participaties ook weer verkopen. Zo kun je inspelen op de markt en houd je controle over je geld.</p>
        <p>Je hoeft zelf geen vastgoedexpert te zijn om succesvol te beleggen. Bij solide woningfondsen is de kennis en knowhow van de woningmarkt aanwezig. En daar profiteer jij van.</p>
        <p>Beheer van woningen kost tijd en geld. Dit is een van de taken van de fondsbeheerder. Omdat deze veel woningen bezit, is het beheer professioneel, efficiënt en vaak goedkoper.</p>
        <p>Bij indirect investeren neemt de fondsbeheerder al dit werk uit handen. Uiteraard vraagt deze hier wel een vergoeding voor. Hiermee ben jij optimaal flexibel en investeer je al met relatief weinig geld.</p>
      `,
      expanded: false,
      image: 'background4.jpg',
    },
    {
      title: 'Rendement uit woningen behaal je op twee manieren',
      content: `
        <p>Het aantrekkelijke van investeren in vastgoed is dat je niet één maar twee rendementen kunt behalen. Er is namelijk het directe rendement en het indirecte rendement.</p>
        <p>De huurprijs is de belangrijkste bron van inkomsten voor de belegger. De huurinkomsten afgezet tegen de aankoopprijs van de woning bepalen het rendement. Dit kan bruto zijn, het Bruto Aanvangsrendement (BAR) of netto, het Netto Aanvangsrendement (NAR). Bij de laatste worden alle kosten zoals belastingen en heffingen, onderhoud, beheer, management en taxaties van de huurinkomsten afgetrokken.</p>
        <p>Het indirecte rendement van beleggen in vastgoed wordt gevormd door de waardeverandering van de woning tussen het moment van aankoop en verkoop. Dit rendement behaal je bij verkoop van de woning. Het directe en indirecte rendement bij elkaar opgeteld vormen het totale rendement van de belegging.</p>
      `,
      expanded: false,
      image: 'background5.jpg',
    },
    {
      title: 'De huurprijzen: belangrijk voor het directe rendement',
      content: `
        <p>Over de afgelopen 10 jaar zijn de huurprijzen van alle huurwoningen gemiddeld met 2,7% per jaar gestegen, met uitschieters van 4,7% en 1,6%.Dit jaar is de huurverhoging 2,9%. Er was slechts 0,1% verschil tussen de socialehuurwoningen (kale huurprijs lager dan € 737,14 per maand, 2020) en de vrijesectorwoningen.</p>
        <p>De huurprijs mag jaarlijks worden geïndexeerd met de inflatie. Bij een wisseling van huurder kan de huurprijs vrij worden bijgesteld. De gemiddelde huurverhoging bij wisseling van huurders was in 2020 9,5 procent.</p>
        <p>Gemiddeld bedraagt de huurprijs in de vrije sector 13 euro per m2. Noord-Holland is met 18 euro per m2 het duurst. Friesland en Drenthe zijn met 9 euro per m2 het goedkoopst.</p>
        <p>De (aankoop)waarden van woningen verschillen eveneens sterk per regio. Daarom kun je ook in een regio met lage huren een aantrekkelijk rendement behalen.</p>
      `,
      expanded: false,
      image: 'background6.jpg',
    },
    {
      title: 'De waarde van de woning: belangrijk voor het indirecte rendement',
      content: `
        <p>De waarde van de woning wordt veel sterker beïnvloed door de economische situatie dan de huurprijzen. Dit zie je onder andere in de jaarlijkse procentuele veranderingen in de koopprijzen. Deze lopen meer uiteen dan die van de huurprijzen.</p>
        <p>Tevens zijn er periodes geweest waarin de waarde van koopwoningen daalde, bijvoorbeeld tussen 2009 en 2013. In 2020 ligt de gemiddelde waarde van een koopwoning ongeveer 40% hoger dan in 2015 en bedraagt 330.000 euro.</p>
        <p>De gemiddelde koopprijs verschilt, net als de huurprijs, sterk per regio. In Noord-Holland zijn de woningen het duurst. In 2019 was de gemiddelde verkoopprijs hier 397.000 euro. In Groningen waren in 2019 de woningen het goedkoopst meteen gemiddelde verkoopprijs van 219.000 euro.</p>
        <p>De huurprijzen van de woningen verschillen eveneens sterk per regio. Daarom kun je ook in een regio waar de woningen duur zijn toch een aantrekkelijk rendement behalen.</p>
      `,
      expanded: false,
      image: 'background7.jpg',
    },
    {
      title: 'Het hefboomeffect: wat is dat precies?',
      content: `
        <p>Een goede belegging levert geld op. In feite maak je geld met geld. Dat heb je wel eerst nodig. Bij een goed rendement is meer geld namelijk vaak welkom. Een belegger of een vastgoedfonds denkt dan aan geld lenen.</p>
        <p>Geld lenen kost geld. Beleggen met vreemd vermogen is daarom alleen interessant als het rendement van de belegging hoger is dan de rente op de financiering. In dat geval is er sprake van een hefboomeffect.</p>
        <p>Een woningfonds kan de aankopen financieren met een combinatie van de inleg van beleggers en vreemd vermogen. Een individuele belegger kan een hypotheek voor een beleggingswoning aanvragen.</p>
        <p>Vanzelfsprekend beoordeelt de bank eerst de netto-inkomsten en de waarde van het vastgoed. De woning is het onderpand voor de lening. Want hier ligt het risico van het gebruik van het hefboomeffect. Als de inkomsten en waarde dalen, kan beleggen met vreemd vermogen meer kosten dan dat het oplevert.</p>
      `,
      expanded: false,
      image: 'background8.jpg',
    },
  ]
}
